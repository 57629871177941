import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Upload, Modal, } from 'antd';
import { HStack, Image, Text, Box, IconButton, Stack } from '@chakra-ui/react';
import { PlusOutlined } from '@ant-design/icons';
import { MdDeleteOutline } from "react-icons/md";
import { showCustomDialog } from '../application/action/app_action';
import { getValidationMessage } from '../common/util';

/// <summary>
/// Author: Saitama
/// </summary>
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/// <summary>
/// Author: Saitama
/// </summary>
const CustomMediaUploader = ({
  id,
  maxImages = 1,
  maxVideos = 0,
  fileUrls = [],
  onChange,
  onDelete,
  allowPdf = false,
  errors,
  patternErrorTxt
}) => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [serverFiles, setServerFiles] = useState([]);
  const _ALLOW_UPLOAD_EXT = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4']

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    setServerFiles(fileUrls);
  }, [fileUrls]);

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const handleCancel = () => setPreviewVisible(false);

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChange({ fileList: newFileList });
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    const isVideo = file.type.startsWith('video/');
    const videoCount = fileList.filter(f => f.type.startsWith('video/')).length + serverFiles.filter(s => s.mediaType == 'Video').length;
    const imageCount = fileList.filter(f => f.type.startsWith('image/')).length + serverFiles.filter(s => s.mediaType == 'Image').length;

    if (allowPdf) {
      _ALLOW_UPLOAD_EXT.push('application/pdf');
    }

    if (!_ALLOW_UPLOAD_EXT.includes(file.type)) {
      _dispatch(showCustomDialog({
        success: false,
        content: `You can only upload image png/jpg/jpeg/mp4 file(s)!`
      }));
      return Upload.LIST_IGNORE;
    }

    if (isVideo && videoCount >= maxVideos) {
      _dispatch(showCustomDialog({
        success: false,
        content: `You can only upload up to ${maxVideos} video file(s)!`
      }));
      return Upload.LIST_IGNORE;
    }

    if (isImage && imageCount >= maxImages) {
      _dispatch(showCustomDialog({
        success: false,
        content: `You can only upload up to ${maxImages} image file(s)!`
      }));
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const deleteMedia = (id) => {
    if (onDelete) {
      onDelete(id);
    }

    setServerFiles(serverFiles.filter(s => s.id != id));
  };

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Stack mb={'20px'}>
      <HStack overflow={'scroll'}>
        {
          serverFiles.map((item, index) => {
            return (
              <>
                {
                  item.mediaType == "PDF" &&
                  <Box position="relative" display="inline-block" minW={['200px', '100px']} onClick={() => window.open(item.mediaUrl, "_blank")}>
                    <Image
                      src={require('../assets/img/pdf.png')}
                      boxSize={['200px', '100px']}
                      style={{ borderStyle: 'dashed', borderWidth: '1px', borderRadius: '5px' }} />
                    <IconButton
                      position="absolute"
                      top={serverFiles.find(s => s.mediaType == "Video") ? "10%" : "20%"}
                      right={serverFiles.find(s => s.mediaType == "Video") ? "-15%" : "-10%"}
                      transform="translate(-50%, -50%)"
                      isRound={true}
                      variant='solid'
                      colorScheme='red'
                      size={'sm'}
                      icon={<MdDeleteOutline />}
                      onClick={() => deleteMedia(item.id)} />
                  </Box>

                }
                {
                  item.mediaType == "Image" &&
                  <Box position="relative" display="inline-block" minW={['200px', '100px']} onClick={() => window.open(item.mediaUrl, "_blank")}>
                    <Image
                      src={item.mediaUrl}
                      boxSize={['200px', '100px']}
                      style={{ borderStyle: 'dashed', borderWidth: '1px', borderRadius: '5px' }} />
                    <IconButton
                      position="absolute"
                      top={serverFiles.find(s => s.mediaType == "Video") ? "10%" : "20%"}
                      right={serverFiles.find(s => s.mediaType == "Video") ? "-15%" : "-10%"}
                      transform="translate(-50%, -50%)"
                      isRound={true}
                      variant='solid'
                      colorScheme='red'
                      size={'sm'}
                      icon={<MdDeleteOutline />}
                      onClick={() => deleteMedia(item.id)} />
                  </Box>

                }
                {
                  item.mediaType == "Video" &&
                  <Box position="relative" display="inline-block" minWidth={['250px', '200px']}>
                    {/* <video style={{ width: '30%' }} controls>
                    <source src={item.mediaUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                    <Box
                      as="iframe"
                      src={item.mediaUrl}
                      width={['250px', '200px']}
                      height={['250px', '200px']}
                      border="none"
                    />
                    <IconButton
                      position="absolute"
                      top="10%"
                      right="-5%"
                      transform="translate(-50%, -50%)"
                      isRound={true}
                      variant='solid'
                      colorScheme='red'
                      size={'sm'}
                      icon={<MdDeleteOutline />}
                      onClick={() => deleteMedia(item.id)} />
                  </Box>
                }
              </>
            )
          })
        }
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={beforeUpload}>
          {serverFiles.length + fileList.length >= maxImages + maxVideos ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} zIndex={999999}>
          {previewImage && (previewImage.endsWith('.mp4') || previewImage.endsWith('.webm')) ? (
            <video style={{ width: '100%' }} controls>
              <source src={previewImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img alt="preview" style={{ width: '100%' }} src={previewImage} />
          )}
        </Modal>
      </HStack>
      {
        errors && errors[id] &&
        <Text color={'red'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
      }
    </Stack>
  );
};

export default CustomMediaUploader;