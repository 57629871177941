import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Text,
    SimpleGrid,
} from "@chakra-ui/react";
import { showCustomDialog } from "../../../../application/action/app_action";
import { HSeparator } from "../../../../components/separator/Separator";
import { ApiKey, RegexPattern } from "../../../../common/constant";
import ApiEngine from "../../../../common/api-engine";
import Routes from "../../../../common/api_routes";
import CustomInput from "../../../../components/custom-input";
import CustomSelect from "../../../../components/custom-select";
import CustomPhoneInput from "../../../../components/custom-phone-input";
import CustomMediaUploader from "../../../../components/custom-media-uploader";
import { createMultiPartFormBody } from "../../../../common/util"

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ onSave, onCancel }) => {
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    var _dispatch = useDispatch();
    const _COUNTRY_LIST = require('../../../../common/countries.json');
    const _SUPPORT_DOCUMENT_TYPE = [
        { label: 'IDENTITY_NO', value: 'Identity No' },
        { label: 'PASSPORT_NO', value: 'Passport No' }
    ];
    const [businessRegFile, setBusinessRegFile] = useState();
    const [previewBusinessRegFile, setPreviewBusinessRegFile] = useState([]);
    const [documentTypeFile, setDocumentTypeFile] = useState();
    const [previewDocumentTypeFile, setPreviewDocumentTypeFile] = useState([]);
    const _NATURE_OF_BUSINESS = [
        { label: 'WHOLESALER', value: 'wholesaler' },
        { label: 'RETAILER', value: 'retailer' }
    ];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (_USER_DATA?.user?.companyProfile?.companyRegistrationDocument) {
            setPreviewBusinessRegFile([{
                id: 'companyRegistrationDocument',
                mediaType: _USER_DATA?.user?.companyProfile?.companyRegistrationDocument?.split('.')[1] == 'pdf' ? 'PDF' : 'Image',
                mediaUrl: _USER_DATA?.user?.companyProfile?.companyRegistrationDocument
            }])
        }

        if (_USER_DATA?.user?.companyProfile?.documentTypeFile) {
            setPreviewDocumentTypeFile([{
                id: 'documentTypeFile',
                mediaType: _USER_DATA?.user?.companyProfile?.documentTypeFile?.split('.')[1] == 'pdf' ? 'PDF' : 'Image',
                mediaUrl: _USER_DATA?.user?.companyProfile?.documentTypeFile
            }])
        }
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onSubmit = async (data, e) => {
        try {
            if (businessRegFile) {
                data['companyRegistrationDocument'] = businessRegFile;
            }

            if (documentTypeFile) {
                data['documentTypeFile'] = documentTypeFile;
            }
            
            Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
            var responseJson = await ApiEngine.post(Routes._COMPANY_PROFILE, createMultiPartFormBody(data), {
                headers: {
                    'content-type': ApiKey._API_MULTIPART_FORM
                }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            onSave();
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('COMPANY_NAME')}
                </Text>
                <Controller
                    control={control}
                    name="companynName"
                    defaultValue={_USER_DATA?.user?.jewellerProfile?.companyName}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            isDisabled
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_COMPANY_NAME"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <HSeparator mb='20px' />
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('ADDRESS')}
                </Text>
                <Controller
                    control={control}
                    name="address"
                    defaultValue={_USER_DATA?.user?.companyProfile?.address}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_ADDRESS"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('ZIPCODE')}
                </Text>
                <Controller
                    control={control}
                    name="zipCode"
                    defaultValue={_USER_DATA?.user?.companyProfile?.zipCode}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_ZIPCODE"}
                            errors={errors}
                            patternErrorTxt={'INVALID_ZIP_CIDE'}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                        pattern: RegexPattern._ZIP_CODE_VALIDATION
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('CITY')}
                </Text>
                <Controller
                    control={control}
                    name="city"
                    defaultValue={_USER_DATA?.user?.companyProfile?.city}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_CITY"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('STATE')}
                </Text>
                <Controller
                    control={control}
                    name="state"
                    defaultValue={_USER_DATA?.user?.companyProfile?.state}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_STATE"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('COUNTRY')}
                </Text>
                <Controller
                    control={control}
                    name="country"
                    defaultValue={_USER_DATA?.user?.companyProfile?.country}
                    render={({ onChange, value, name }) => (
                        <CustomSelect
                            id={name}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            errors={errors}
                            showEmptyOption
                            options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <HSeparator mb='20px' />
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('COMPANY_WEBSITE')}
                </Text>
                <Controller
                    control={control}
                    name="companyWebsite"
                    defaultValue={_USER_DATA?.user?.companyProfile?.companyWebsite}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_COMPANY_WEBSITE"}
                            errors={errors}
                        />
                    )}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('COMPANY_EMAIL')}
                </Text>
                <Controller
                    control={control}
                    name="companyEmail"
                    defaultValue={_USER_DATA?.user?.companyProfile?.companyEmail}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_COMPANY_EMAIL"}
                            errors={errors}
                            patternErrorTxt={'INVALID_EMAIL'}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                        pattern: RegexPattern._EMAIL_VALIDATION
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('TELEPHONE')}
                </Text>
                <Controller
                    control={control}
                    name="companyTelephone"
                    defaultValue={_USER_DATA?.user?.companyProfile?.companyTelephone}
                    render={({ onChange, value, name }) => (
                        <CustomPhoneInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            errors={errors} />
                    )}
                    rules={{
                        required: "REQUIRED"
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('BUSINESS_REG_NUMBER')}
                </Text>
                <Controller
                    control={control}
                    name="companyRegistrationNumber"
                    defaultValue={_USER_DATA?.user?.companyProfile?.companyRegistrationNumber}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_BUSINESS_REG_NUMBER"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('BUSINESS_REG_DOCUMENT')}
                </Text>
                <Controller
                    control={control}
                    name="companyRegistrationDocument"
                    defaultValue={_USER_DATA?.user?.companyProfile?.companyRegistrationDocument}
                    render={({ onChange, value, name }) => (
                        <CustomMediaUploader
                            id={name}
                            allowPdf
                            fileUrls={previewBusinessRegFile}
                            onChange={({ fileList: newFileList }) => {
                                setBusinessRegFile(newFileList);
                                onChange(newFileList);
                            }}
                            onDelete={(id) => { setPreviewBusinessRegFile([]); }}
                            errors={errors} />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('NATURE_OF_BUSINESS')}
                </Text>
                <Controller
                    control={control}
                    name="natureOfBusiness"
                    defaultValue={_USER_DATA?.user?.companyProfile?.natureOfBusiness}
                    render={({ onChange, value, name }) => (
                        <CustomSelect
                            id={name}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            errors={errors}
                            showEmptyOption
                            options={_NATURE_OF_BUSINESS}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            {/* <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('DIRECTOR_PARTNER_NAME')}
                </Text>
                <Controller
                    control={control}
                    name="directorName"
                    defaultValue={_USER_DATA?.user?.companyProfile?.directorName}
                    render={({ onChange, value, name }) => (
                        <CustomInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeHolder={"ENTER_DIRECTOR_PARTNER_NAME"}
                            errors={errors}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('DIRECTOR_PARTNER_TELEPHONE')}
                </Text>
                <Controller
                    control={control}
                    name="directorTelephone"
                    defaultValue={_USER_DATA?.user?.companyProfile?.directorTelephone}
                    render={({ onChange, value, name }) => (
                        <CustomPhoneInput
                            id={name}
                            onChange={(value) => onChange(value)}
                            value={value}
                            errors={errors} />
                    )}
                    rules={{
                        required: "REQUIRED"
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SUPPORT_DOCUMENT')}
                </Text>
                <Controller
                    control={control}
                    name="documentDescription"
                    defaultValue={_USER_DATA?.user?.companyProfile?.documentDescription}
                    render={({ onChange, value, name }) => (
                        <CustomSelect
                            id={name}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            value={value}
                            errors={errors}
                            showEmptyOption
                            options={_SUPPORT_DOCUMENT_TYPE}
                        />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }}>
                <Text
                    display={"flex"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    alignItems={'center'}
                    mb={'20px'}
                >
                    {t('SUPPORT_DOCUMENT_FILE')}
                </Text>
                <Controller
                    control={control}
                    name="documentTypeFile"
                    defaultValue={_USER_DATA?.user?.companyProfile?.documentTypeFile ?? ''}
                    render={({ onChange, value, name }) => (
                        <CustomMediaUploader
                            id={name}
                            allowPdf
                            fileUrls={previewDocumentTypeFile}
                            onChange={({ fileList: newFileList }) => {
                                setDocumentTypeFile(newFileList);
                                onChange(newFileList);
                            }}
                            onDelete={(id) => { setPreviewDocumentTypeFile([]); }}
                            errors={errors} />
                    )}
                    rules={{
                        required: true,
                        message: "REQUIRED",
                    }}
                />
            </SimpleGrid> */}
            <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="50%"
                h="50"
                type="submit">
                {t('NEXT')}
            </Button>
        </form>
    );
}
