import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FormLabel, Text, Input, InputGroup, InputRightElement, Textarea, Stack, Icon } from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { getValidationMessage, stringIsNullOrEmpty } from "../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
const CustomInput = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        errors,
        patternErrorTxt,
        label,
        placeHolder,
        isPassword = false,
        inputType = 'text', // tel | number, ...
        icon,
        isTextArea = false,
        style,
        isRequired = false,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(!isPassword);

    return (
        <Stack mb={'20px'} {...style}>
            {
                !stringIsNullOrEmpty(label) &&
                <FormLabel
                    display={"flex"}
                    ms={"4px"}
                    fontSize={"md"}
                    fontWeight={"500"}
                    mb={"3px"}
                >
                    {t(label)} {isRequired && <Text color={'red'}>*</Text>}
                </FormLabel>
            }
            <InputGroup size="md">
                {
                    isTextArea ?
                        <Textarea
                            id={id}
                            name={id}
                            value={value}
                            onInput={onChange}
                            placeholder={t(placeHolder)}
                            ms={{ base: "0px", md: "0px" }}
                            fontWeight={"500"}
                            _focusVisible={'none'}
                            {...restProps} /> :
                        <Input
                            id={id}
                            name={id}
                            value={value}
                            onInput={onChange}
                            type={showPassword ? inputType : 'password'}
                            placeholder={t(placeHolder)}
                            variant="auth"
                            ms={{ base: "0px", md: "0px" }}
                            fontWeight={"500"}
                            autoComplete={"off"}
                            {...restProps} />
                }
                {
                    isPassword &&
                    <InputRightElement display="flex" alignItems="center" mt="4px" >
                        <Icon
                            _hover={{ cursor: "pointer" }}
                            as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </InputRightElement>
                }
                {
                    icon &&
                    <InputRightElement width='3.5rem'>
                        {icon}
                    </InputRightElement>
                }
            </InputGroup>
            {
                errors && errors[id] &&
                <Text color={'red'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
            }
        </Stack>
    )
});

export default CustomInput;