import React from "react";
import { useTranslation } from 'react-i18next';
import { FormLabel, Select, Stack, Text } from '@chakra-ui/react';
import { getValidationMessage } from "../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
const CustomSelect = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        errors,
        patternErrorTxt,
        label,
        style,
        size = 'md',
        isDisabled = false,
        options = [],
        placeholder = '',
        showEmptyOption = false,
        ...restProps
    } = props;
    const { t } = useTranslation();

    return (
        <Stack mb={'20px'} {...style}>
            <FormLabel
                display={"flex"}
                ms={"4px"}
                fontSize={"md"}
                fontWeight={"500"}
                mb={"3px"}
            >
                {t(label)}
            </FormLabel>
            <Select
                id={id}
                isDisabled={isDisabled}
                onChange={(value) => onChange(value)}
                value={value}
                variant="auth"
                size={size}
                placeholder={t(placeholder)}
                {...restProps}>
                {
                    showEmptyOption &&
                    <option value={''}>{t('PLEASE_SELECT')}</option>
                }
                {
                    options.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>{t(item.label)}</option>
                        )
                    })
                }
            </Select>
            {
                errors && errors[id] &&
                <Text ml={'8px'} color={'red'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
            }
        </Stack>
    )
});

export default CustomSelect;