import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Portal, Box, useDisclosure, Text, Button, Link } from '@chakra-ui/react';
import Footer from '../../components/footer/FooterAdmin.js';
import Navbar from '../../components/navbar/NavbarAdmin.js';
import Sidebar from '../../components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import { _AUTH_ROUTES, WebUrl } from "../../routes";
import { filterMenu, isPathExist, isObjectEmpty } from 'common/util';
import { useRouter } from 'common/useRouter';

/// <summary>
/// Author: Saitama
/// </summary>
export default function Dashboard(props) {
	var _router = useRouter();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const { ...rest } = props;
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const { onOpen } = useDisclosure();
	const _currentLocation = useLocation();

	/// <summary>
	/// Author: Saitama
	/// </summary>
	useEffect(() => {
		if (_currentLocation) {
			if (isObjectEmpty(isPathExist(filterMenu(_AUTH_ROUTES, _USER_DATA?.user?.roleId), _currentLocation.pathname))) {
				_router.navigate(WebUrl._DEFAULT, true);
			}
		}
	}, [_currentLocation]);

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const getActiveRoute = (routes) => {
		return isPathExist(routes, _currentLocation.pathname)?.name ?? 'All In One';
	};

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.component) {
				return <Route path={prop.path} element={prop.component} key={key} />;
			}
			else if (prop.category) {
				return getRoutes(prop.items);
			}
			else {
				return null;
			}
		});
	};

	return (
		<Box>
			<Box>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar routes={filterMenu(_AUTH_ROUTES, _USER_DATA?.user?.roleId)} display='none' {...rest} />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflow='auto'
						position='relative'
						maxHeight='100%'
						w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						<Portal>
							<Box>
								<Navbar
									onOpen={onOpen}
									logoText={'Horizon UI Dashboard PRO'}
									brandText={getActiveRoute(_AUTH_ROUTES)}
									fixed={fixed}
									{...rest}
								/>
							</Box>
						</Portal>

						<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
							<Routes location={_currentLocation}>
								{getRoutes(filterMenu(_AUTH_ROUTES, _USER_DATA?.user?.roleId))}
							</Routes>
						</Box>
						<Box>
							<Footer />
						</Box>
					</Box>
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
