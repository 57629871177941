import {
    _APP_BUSY,
    _APP_FREE,
    _SHOW_CUSTOM_DIALOG,
    _DISPOSE_CUSTOM_DIALOG,
    _SHOW_CUSTOM_INPUT_DIALOG,
    _DISPOSE_CUSTOM_INPUT_DIALOG
} from "../action/app_action";

const _INITIAL_STATE = {
    customDialogData: {},
    appIsBusy: false,
    buttonClickTime: null
};

var _busyCount = 0;

/// <summary>
/// Author: Saitama
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _APP_BUSY:
            _busyCount++;
            return {
                ...state,
                appIsBusy: true
            };
        case _APP_FREE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                appIsBusy: _busyCount != 0
            };
        case _DISPOSE_CUSTOM_DIALOG:
            return {
                ...state,
                customDialogData: {}
            };
        case _SHOW_CUSTOM_DIALOG:
            return {
                ...state,
                customDialogData: action.data
            };
        case _DISPOSE_CUSTOM_INPUT_DIALOG:
            return {
                ...state,
                customInputDialogData: {}
            };
        case _SHOW_CUSTOM_INPUT_DIALOG:
            return {
                ...state,
                customInputDialogData: action.data
            };
        default:
            return state;
    }
};