import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import { MdDiamond } from "react-icons/md";
import {
	Box,
	FormLabel,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Image,
	Text,
	Flex,
	Tooltip,
	Button
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import { BsSave, BsBagHeart } from "react-icons/bs";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
	Role,
	_DIAMOND_SHAPE_OPTIONS,
	_DIAMOND_CLARITY_OPTIONS,
	_DIAMOND_COLOR_OPTIONS,
	_DIAMOND_FINISH_LEVEL_OPTIONS,
	_DIAMOND_FLUORESCENSE_OPTIONS,
	_DIAMOND_LAB_OPTIONS,
	_DIAMOND_TYPE_OPTION,
	_DIAMOND_STOCK_STATUS_OPTIONS,
	DiamondStockStatus,
	JewellerProfileStatus,
	ApiKey,
	_DIAMOND_AVAILABILITY_OPTIONS
} from "../../../common/constant";
import { isVerifiedJeweller } from "../../../common/util";
import { showCustomDialog, showCustomInputDialog, disposeCustomInputDialog } from "../../../application/action/app_action";
import moment from "moment";
import ApiEngine from "../../../common/api-engine";
import { NavLink, useLocation } from "react-router-dom";
import { stringToIntArray, createMultiPartFormBody, numberWithCurrencyFormat, renderDiamondTypeBadge } from '../../../common/util';
import DiamondDetailModal from "../../../components/diamond-detail-modal";
import DiamondDetail from "../../../components/diamond-detail";
import DiamondDetailGrid from "../../../components/diamond-detail-grid";
import HoldDiamondModal from "../../../components/hold-diamond-modal";
import ConfirmDiamondModal from "../../../components/confirm-diamond-modal";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState();
	let { search } = useLocation();
	const _query = new URLSearchParams(search);
	const [postData, setPostData] = useState({});
	const [uploadModal, setUploadModal] = useState({});
	const [uploadedMedia, setUploadedMedia] = useState([]);
	const [mediaToDelete, setMediaToDelete] = useState([]);
	const [selectedDiamonds, setSelectedDiamonds] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [allDiamonds, setAllDiamonds] = useState([]);
	const [holdDiamondModal, setHoldDiamondModal] = useState(false);
	const [confirmDiamondModal, setConfirmDiamondModal] = useState(false);

	const _COLUMNS = [
		{
			id: 'checkbox',
			headerWidth: '1%',
			customHeader: (
				<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
					<input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
					<label htmlFor="checkboxSelectAll"></label>
				</div>
			),
			Cell: ({ row }) => {
				return (
					<div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
						<input
							id={`checkbox${row.id}`}
							type="checkbox"
							onChange={() => updateSelectedDiamonds(row.original)}
							checked={selectedDiamonds.find(t => t.id == row.original.id)} />
						<label htmlFor={`checkbox${row.id}`}></label>
					</div>
				)
			},
			width: 30,
			disableSortBy: true,
		},
		{
			Header: "STOCK_NO",
			accessor: "stockNo",
			Cell: ({ row }) => {
				return <Flex alignItems={'center'}>
					{renderDiamondTypeBadge(row.original.typeId)}
					<Text ml="3px">{row.original.stockNo}</Text>
				</Flex>
			}
		},
		{
			Header: "DETAIL",
			Cell: ({ row }) => {
				return <Tooltip label={t("DETAIL")}><IconButton style={{ padding: '10px' }} bgColor={"brand.800"} color={'white'} icon={<MdDiamond size={'16px'} />} onClick={() => setUploadModal(row.original)} /></Tooltip>
			}
		},
		{
			Header: "SHAPE",
			accessor: "shapeId",
			Cell: ({ row }) => {
				return <Tooltip label={t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.label)}><Image className="diamond-shape-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.image} /></Tooltip>
			}
		},
		{
			Header: "CARAT",
			accessor: "carat"
		},
		{
			Header: "COLOR",
			accessor: "colorId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_COLOR_OPTIONS.find(i => i.id == row.original.colorId)?.label)}</Text>
			}
		},
		{
			Header: "CLARITY",
			accessor: "clarityId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == row.original.clarityId)?.label)}</Text>
			}
		},
		{
			Header: "CUT",
			accessor: "cutFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.cutFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "POLISH",
			accessor: "polishFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.polishFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "SYMMETRY",
			accessor: "symmetryFinishLevelId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == row.original.symmetryFinishLevelId)?.label)}</Text>
			}
		},
		{
			Header: "FLUO",
			accessor: "fluorescenceId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == row.original.fluorescenceId)?.label)}</Text>
			}
		},
		{
			Header: "LAB",
			accessor: "labId",
			Cell: ({ row }) => {
				return <Text>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}</Text>
			}
		},
		{
			Header: "CERTIFICATE",
			accessor: "certificateId"
		},
		{
			Header: "PRICE_PER_CARAT",
			accessor: "pricePerCarat",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.pricePerCarat : row.original.markupPricePerCarat)}</Text>
			}
		},
		{
			Header: "TOTAL",
			Cell: ({ row }) => {
				return <Text>${numberWithCurrencyFormat(_USER_DATA?.user?.roleId == Role._SUPPLIER ? row.original.price : row.original.totalPrice)}</Text>
			}
		}
	];

	/// <summary>
	/// Author: CK
	/// </summary> 
	function updateSelectedDiamonds(diamond) {
		if (selectedDiamonds.filter(i => i.id == diamond.id).length) {
			setSelectedDiamonds(selectedDiamonds.filter(i => i.id != diamond.id));
		}
		else {
			setSelectedDiamonds([...selectedDiamonds, diamond]);
		}
	}

	/// <summary>
	/// Author: CK
	/// </summary> 
	useEffect(() => {
		let diamonds = [];

		if (checkAll && allDiamonds.length > 0) {
			diamonds = allDiamonds.map(selected => selected);
		}

		setSelectedDiamonds(diamonds);
	}, [checkAll]);

	/// <summary>
	/// Author: CK
	/// </summary>
	const holdDiamonds = async (param) => {
		try {
			var responseJson = await ApiEngine.post(Routes._HOLD_DIAMONDS, param);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showCustomDialog({
						success: true,
						content: responseJson[ApiKey._API_MESSAGE_KEY],
					})
				);
				setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
				setSelectedDiamonds([]);
				setCheckAll(false);
				setHoldDiamondModal(false);
			}
			else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	/// <summary>
	/// Author: Saitama
	/// </summary>
	const orderDiamonds = async (params) => {
		try {
			var responseJson = await ApiEngine.post(Routes._ORDER_DIAMONDS, { diamondIds: params });

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			_dispatch(
				showCustomDialog({
					success: true,
					content: responseJson[ApiKey._API_MESSAGE_KEY],
					onConfirm: () => _router.navigate(WebUrl._PURCHASEMENT_CONFIRM_DIAMOND_LIST)
				})
			);
			setApiUrl(Routes._INVENTORY_DIAMONDS + '?v=' + moment());
			setSelectedDiamonds([]);
			setCheckAll(false);
			setConfirmDiamondModal(false);
		}
		catch (err) {
			_dispatch(
				showCustomDialog({
					success: false,
					content: err,
				})
			);
		}
	};

	/// <summary>
	/// Author: CK
	/// Edited: Saitama
	/// </summary>
	useEffect(() => {
		(async () => {
			let statusCode = await isVerifiedJeweller(_USER_DATA?.user);

			if (statusCode == JewellerProfileStatus._SUCCESS) {
				let currentApiUrl = `${Routes._INVENTORY_DIAMONDS}?`;
				let currentPostData = {};

				if (_USER_DATA?.user?.roleId == Role._SUPPLIER) {
					currentPostData['supplierId'] = _USER_DATA?.user?.id;
				}

				if (_query?.get('minct')) {
					currentPostData['minCt'] = parseFloat(_query?.get('minct'));
				}

				if (_query?.get('maxct')) {
					currentPostData['maxct'] = parseFloat(_query?.get('maxct'));
				}

				if (_query?.get('typeId')) {
					currentPostData['typeId'] = parseFloat(_query?.get('typeId'));
				}

				if (_query?.get('isFancyColor')) {
					currentPostData['isFancyColor'] = _query?.get('isFancyColor');
				}

				if (_query?.get('shape')) {
					currentPostData['shape'] = stringToIntArray(_query?.get('shape'));
				}

				if (_query?.get('color')) {
					currentPostData['color'] = stringToIntArray(_query?.get('color'));
				}

				if (_query?.get('fancyColor')) {
					currentPostData['fancyColor'] = stringToIntArray(_query?.get('fancyColor'));
				}

				if (_query?.get('fancyColorIntensity')) {
					currentPostData['fancyColorIntensity'] = stringToIntArray(_query?.get('fancyColorIntensity'));
				}

				if (_query?.get('fancyColorOvertone')) {
					currentPostData['fancyColorOvertone'] = stringToIntArray(_query?.get('fancyColorOvertone'));
				}

				if (_query?.get('clarity')) {
					currentPostData['clarity'] = stringToIntArray(_query?.get('clarity'));
				}

				if (_query?.get('eyeclean')) {
					currentPostData['eyeClean'] = stringToIntArray(_query?.get('eyeclean'));
				}

				if (_query?.get('shade')) {
					currentPostData['shade'] = stringToIntArray(_query?.get('shade'));
				}

				if (_query?.get('milky')) {
					currentPostData['milky'] = stringToIntArray(_query?.get('milky'));
				}

				if (_query?.get('cut')) {
					currentPostData['cut'] = stringToIntArray(_query?.get('cut'));
				}

				if (_query?.get('polish')) {
					currentPostData['polish'] = stringToIntArray(_query?.get('polish'));
				}

				if (_query?.get('symmetry')) {
					currentPostData['symmetry'] = stringToIntArray(_query?.get('symmetry'));
				}

				if (_query?.get('fluo')) {
					currentPostData['fluoresence'] = stringToIntArray(_query?.get('fluo'));
				}

				if (_query?.get('location')) {
					currentPostData['location'] = stringToIntArray(_query?.get('location'));
				}

				if (_USER_DATA?.user?.roleId != Role._SUPPLIER) {
					currentPostData['status'] = DiamondStockStatus._ALL_PUBLISHED;
				}

				setPostData(currentPostData);
				setApiUrl(currentApiUrl);
			}
			else {
				_dispatch(
					showCustomDialog({
						success: false,
						content: statusCode == JewellerProfileStatus._PENDING_APPROVAL ? 'WE_ARE_CURRENTLY_PROCESSING_YOUR_ACCOUNT' : "PLEASE_COMPLETE_YOUR_COMPANY_PROFILE_TO_PROCEED",
						confirmTxt: statusCode == JewellerProfileStatus._PENDING_APPROVAL ? 'OK' : "COMPANY_PROFILE",
						onConfirm: () => {
							if (statusCode == JewellerProfileStatus._EMPTY_PROFILE) {
								_router.navigate(WebUrl._JEWELLER_PROFILE_SETTINGS);
							}
						}
					})
				);
			}

		})();
	}, []);

	/// <summary>
	/// Author: CK
	/// </summary>
	useEffect(() => {

	}, []);

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				{
					selectedDiamonds.length > 0 &&
					<Flex justifyContent={'flex-end'}>
						<Button
							variant="brand"
							fontSize="sm"
							fontWeight="500"
							type="button"
							leftIcon={<BsSave />}
							onClick={() => {
								setHoldDiamondModal(true);
							}}
						>
							{t('HOLD')}
						</Button>
						<Button
							isDisabled={selectedDiamonds.length <= 0}
							colorScheme="green"
							fontSize="sm"
							fontWeight="500"
							type="button"
							ml="5px"
							leftIcon={<BsBagHeart />}
							onClick={() => {
								setConfirmDiamondModal(true);
							}}
						>
							{t('ORDER')}
						</Button>
					</Flex>
				}
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					objFilter={postData}
					requestMethod={ApiKey._API_POST}
					customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
					RowAccordionContent={DiamondDetail}
					GridViewItem={DiamondDetailGrid}
				/>
			</Card>
			<DiamondDetailModal
				uploadModal={uploadModal}
				setUploadModal={setUploadModal}
				setMediaToDelete={setMediaToDelete}
				mediaToDelete={mediaToDelete}
				setUploadedMedia={setUploadedMedia}
			/>
			<HoldDiamondModal
				modalVisible={holdDiamondModal}
				setModalVisible={setHoldDiamondModal}
				selectedDiamonds={selectedDiamonds}
				holdDiamonds={holdDiamonds}
			/>
			<ConfirmDiamondModal
				modalVisible={confirmDiamondModal}
				setModalVisible={setConfirmDiamondModal}
				selectedDiamonds={selectedDiamonds}
				orderDiamonds={orderDiamonds}
			/>
		</Box >
	)
}