import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Heading } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Heading size="lg" mb={25} style={{ fontStyle: 'italic' }}>JW Diamond</Heading>
      <HSeparator />
    </Flex>
  );
}

export default SidebarBrand;
