import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Flex,
	Text,
	Image,
    Link
} from "@chakra-ui/react";
import { BsFileEarmarkExcel, BsFileEarmarkCheck } from "react-icons/bs";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import DiamondDetail from "../../../components/diamond-detail";
import { WebUrl } from "../../../routes";
import { Role, ApiKey, OrderStatus, _DIAMOND_SHAPE_OPTIONS, _DIAMOND_LAB_OPTIONS } from "../../../common/constant";
import { showCustomDialog, showCustomInputDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from "../../../common/util";
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._INVENTORY_ORDER}?status=${OrderStatus._PENDING}`);

	const _COLUMNS = [
        {
            Header: "ORDER_NO",
            accessor: "orderNo",
            headerClassName: "col-align-center",
            Cell: ({ row }) => {
                return <Flex flexDir={"column"}>
                    <Text textAlign={'center'} fontWeight={'bold'} fontSize={'14px'}>{row.original.orderNo}</Text>
                    <Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
                </Flex>
            }
        },
		{
            Header: "DIAMOND_DETAILS",
            accessor: "label",
            Cell: ({ row }) => {
                let diamondImg = row.original.diamond.diamondMedias.find(item => item.mediaType === 'Image');

                return <Flex flexDirection={'row'} alignItems={'center'}>
                    {
                        diamondImg ?
                            <Image className="table-diamond-img" src={diamondImg.mediaUrl} />
                            : <Image className="table-diamond-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.diamond.shapeId)?.defaultImg} />
                    }
                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                        <Flex alignItems={'center'}>
                            <Flex alignItems={'center'} mr='5px'>
                                {renderDiamondTypeBadge(row.original.diamond.typeId)}
                                <Text
                                    ml='3px'
                                    fontSize={'13px'}
                                >
                                    {row.original.diamond.stockNo}
                                </Text>
                            </Flex>
                            <Link
                                fontSize={'13px'}
                                color='gold.600'
                                href={getLabCertificateTrackUrl(row.original.diamond.labId, row.original.diamond.certificateId)} isExternal>
                                {_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.diamond.labId)?.label}-{row.original.diamond.certificateId}
                            </Link>
                        </Flex>
                        <Text fontWeight={'bold'} fontSize={'16px'}>{row.original.diamond.label}</Text>
                    </Flex>
                </Flex>
            }
        },
		{
			Header: "STATUS",
			Cell: ({ row }) => {
				return <Badge variant='subtle' colorScheme={'orange'}>{t('PENDING')}</Badge>
			}
		},
		{
            Header: "TOTAL_PRICE",
            headerClassName: "col-align-right",
            Cell: ({ row }) => {
                return <Flex flexDir="column" alignItems={'flex-end'}>
                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalPrice)}</Text>
                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(row.original.markupPricePerCarat))}</Text>
                </Flex>
            }
        }
	];

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					RowAccordionContent={DiamondDetail}
					RowAccordionContentItem={'diamond'} />
			</Card>
		</Box>
	)
}