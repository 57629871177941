import { Box, Flex, Stack } from "@chakra-ui/react";
import Brand from "../components/Brand";
import Links from "../components/Links";
import React from "react";

/// <summary>
/// Author: Saitama
/// </summary>
function SidebarContent(props) {
  const { routes, onClose } = props;

  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack className="sidebar-menu" direction='column' mb='auto' mt='8px'>
        <Box pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} onClose={onClose} />
        </Box>
      </Stack>
      <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
      </Box>
    </Flex>
  );
}

export default SidebarContent;
