import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import { WebUrl, _NON_AUTH_ROUTES } from "../../routes";
import { useRouter } from "../../common/useRouter";
import { isPathExist, isObjectEmpty } from "../../common/util";

/// <summary>
/// Author: Saitama
/// </summary>
export default function NonAuth() {
  var _router = useRouter();
  const authBg = useColorModeValue("white", "navy.900");
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const _currentLocation = useLocation();

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    if (_currentLocation) {
      if (isObjectEmpty(isPathExist(_NON_AUTH_ROUTES, _currentLocation.pathname))) {
        _router.navigate(WebUrl._DEFAULT, true);
      }
    }
  }, [_currentLocation]);

  /// <summary>
  /// Author: Saitama
  /// </summary>
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.component) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      else if (prop.category) {
        return getRoutes(prop.items);
      }
      else {
        return null;
      }
    });
  };

  return (
    <Box>
      <SidebarContext.Provider
        value={{ toggleSidebar, setToggleSidebar }}>
        <Box
          bg={authBg}
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          <Box mx='auto' minH='100vh'>
            <Routes location={_currentLocation}>
              {getRoutes(_NON_AUTH_ROUTES)}
            </Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
