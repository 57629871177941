import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useRouter } from "../../../common/useRouter";
import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
    useColorModeValue,
    SimpleGrid,
    Stack
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../../components/custom-input";
import CustomPhoneInput from "../../../components/custom-phone-input";
import CustomSelect from "../../../components/custom-select";
import { RegexPattern, ApiKey, Role } from "../../../common/constant";
import { WebUrl } from "../../../routes";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api-engine";
import Routes from "../../../common/api_routes";
import Footer from "../../../components/footer/FooterAuth";
import FixedPlugin from "../../../components/fixedPlugin/FixedPlugin";

/// <summary>
/// Author: Saitama
/// </summary>
function Register() {
    var _router = useRouter();
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.700", "white");
    const _COUNTRY_LIST = require('../../../common/countries.json');

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmPassword", "password"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            let params = {
                "password": data.confirmPassword,
                "isActive": true,
                "roleId": Role._JEWELLER,
                "firstName": data.firstName,
                "lastName": data.lastName,
                "email": data.email,
                "phoneNumber": data.phoneNumber,
                "companyName": data.companyName,
                "country": data.country,
                "city": data.city
            };
            var responseJson = await ApiEngine.post(Routes._USER_REGISTER, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: t("ACCOUNT_ACTIVATION_LINK_ALREADY_SENT_TO", { email: data.email }),
                onConfirm: () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _router.navigate(WebUrl._SIGN_IN, true);
                    }
                }
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    return (
        <Stack
            h='max-content'
            mx='auto'
            pt={{ sm: "50px", md: "0px" }}
            px={{ lg: "30px", xl: "0px" }}
            ps={{ xl: "70px" }}>
            <Box
                mx='auto'
                p={{ base: '20px', md: '200px' }}
                pe='20px'
                pt='30px'
                alignContent='center'
                h={{
                    sm: "initial",
                    md: "unset",
                    lg: "100vh",
                    xl: "97vh",
                }}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Heading color={textColor} fontSize="36px" mb="30px" textAlign={'center'}>
                        {t("CREATE_AN_ACCOUNT")}
                        <Text fontSize={'medium'}>{t('PLEASE_ENTER_INFO_IT_S_FREE')}</Text>
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={{ base: 5, md: 10 }}>
                        <FormControl maxW={'100%'}>
                            <Heading color={textColor} fontSize="20px" mb="20px">
                                {t("PERSONAL_INFO")}
                            </Heading>
                            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={{ base: 0, md: 5 }}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    defaultValue={""}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) => onChange(value)}
                                            value={value}
                                            placeHolder={"FIRST_NAME"}
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: "REQUIRED"
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="lastName"
                                    defaultValue={""}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) => onChange(value)}
                                            value={value}
                                            placeHolder={"LAST_NAME"}
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: "REQUIRED",
                                    }}
                                />
                            </SimpleGrid>
                            <Controller
                                control={control}
                                name="email"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        placeHolder={"ENTER_EMAIL"}
                                        errors={errors}
                                        patternErrorTxt={'INVALID_EMAIL'}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED",
                                    pattern: RegexPattern._EMAIL_VALIDATION
                                }}
                            />
                            <Controller
                                control={control}
                                name="password"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        isPassword
                                        placeHolder={"ENTER_PASSWORD"}
                                        errors={errors}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED"
                                }}
                            />
                            <Controller
                                control={control}
                                name="confirmPassword"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        isPassword
                                        placeHolder={"ENTER_CONFIRM_PASSWORD"}
                                        errors={errors}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED",
                                    validate: {
                                        matchingPassword: value => doPasswordMatch(value === watch('password')) || 'PASSWORD_NOT_MATCH_MSG'
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl maxW={'100%'}>
                            <Heading color={textColor} fontSize="20px" mb="20px">
                                {t("COMPANY_INFO")}
                            </Heading>
                            <Controller
                                control={control}
                                name="companyName"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        placeHolder={"ENTER_COMPANY_NAME"}
                                        errors={errors}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED"
                                }}
                            />
                            <Controller
                                control={control}
                                name="phoneNumber"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomPhoneInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        errors={errors} />
                                )}
                                rules={{
                                    required: "REQUIRED"
                                }}
                            />
                            <Controller
                                control={control}
                                name="country"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomSelect
                                        id={name}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                        value={value}
                                        errors={errors}
                                        showEmptyOption
                                        selectTerm={'COUNTRY'}
                                        options={_COUNTRY_LIST.map(c => { return { label: c.name, value: c.name } })}
                                    />
                                )}
                                rules={{
                                    required: true,
                                    message: "REQUIRED",
                                }}
                            />
                            <Controller
                                control={control}
                                name="city"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        placeHolder={"ENTER_CITY"}
                                        errors={errors}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED"
                                }}
                            />
                        </FormControl>
                    </SimpleGrid>
                    <Stack align={'center'}>
                        <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w={{ base: '100%', md: '30%' }}
                            h="50"
                            marginBlock="10px"
                            type="submit"
                        >
                            {t('JOIN_US')}
                        </Button>
                        <Flex
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="start"
                            maxW="100%"
                            mt="0px"
                        >
                            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                                {t('ALREADY_HAVE_ACCOUNT_?')}
                                <NavLink to={WebUrl._SIGN_IN}>
                                    <Text
                                        color={textColorBrand}
                                        as="span"
                                        ms="5px"
                                        fontWeight="500"
                                    >
                                        {t('LOGIN_HERE')}
                                    </Text>
                                </NavLink>
                            </Text>
                        </Flex>
                    </Stack>
                </form>
                <FixedPlugin />
            </Box>
            <Footer />
        </Stack>

    );
}

export default Register;
