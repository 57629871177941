import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    Card,
    CardBody,
    Image,
    Stack,
    CardFooter,
    Heading,
    Tooltip,
    AbsoluteCenter,
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from "../common/util";
import { showCustomDialog } from "../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import ApiEngine from "../common/api-engine";
import { WebUrl } from "../routes";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";
import DiamondMedia from './diamond-media';

/// <summary>
/// Author: CK
/// </summary>
const DiamondDetailGrid = React.forwardRef((props, ref) => {
    const {
        item,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: CK
    /// </summary>
    const imagesSrc = () => {
        return item?.original?.diamondMedias?.length > 0 ? item?.original?.diamondMedias.map(i => {
            return {
                original: i.mediaUrl,
                thumbnail: i.mediaUrl
            }
        }) :
            _DIAMOND_SHAPE_OPTIONS.filter(i => i.id == item?.original?.shapeId).map(i => {
                return {
                    original: i.defaultImg,
                    thumbnail: i.defaultImg
                }
            });
    }

    return (
        <Card className="diamond-detail-grid">
            <Flex flex={5} flexDir={'column'}>
                <AbsoluteCenter left={5} top={5} zIndex={1}>
                    {item?.cells.filter(i => i.column.id == 'checkbox')[0]?.render('Cell')}
                </AbsoluteCenter>
                <Flex flex={1} flexDir={'column'}>
                    <DiamondMedia showThumbnail={false} item={item?.original} medias={item?.original?.diamondMedias} />
                </Flex>
                <Flex className="diamond-detail-grid-row" justifyContent={'space-between'}>
                    <Flex alignItems={'center'}>
                        {renderDiamondTypeBadge(item?.original?.typeId)}
                        <Text
                            ml='3px'
                            fontSize={'13px'}
                        >
                            {item?.original?.stockNo}
                        </Text>
                    </Flex>
                    <Link
                        fontSize={'13px'}
                        color='gold.600'
                        href={getLabCertificateTrackUrl(item?.original?.labId, item?.original?.certificateId)} isExternal>
                        {_DIAMOND_LAB_OPTIONS.find(i => i.id == item?.original?.labId)?.label}-{item?.original?.certificateId}
                    </Link>
                </Flex>
                <Flex flex={1} flexDir={'column'} className="diamond-detail-grid-row">
                    <Flex>
                        <Tooltip label={t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == item?.original?.shapeId)?.label)}><Image className="diamond-shape-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == item?.original?.shapeId)?.image} /></Tooltip>
                        <Text ml={"10px"} fontWeight={700} fontSize={'16px'} textAlign={'left'} lineHeight={"18px"}>{item?.original?.label}</Text>
                    </Flex>
                </Flex>
                {
                    <Flex className="diamond-detail-grid-row" justifyContent={'space-between'} alignItems={'center'}>
                        <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(item?.original?.markupPricePerCarat))}</Text>
                        <Text color='gold.600' fontWeight={700} fontSize={'22px'}>${numberWithCurrencyFormat(item?.original?.totalPrice)}</Text>
                    </Flex>
                }
                <Flex className="diamond-detail-grid-row" justifyContent={'space-between'} alignItems={'center'}>
                    <Flex justifyContent={'center'}>
                        <Text color="secondaryGray.500" fontSize={'12px'} mr={'2px'}>M: </Text>
                        <Text fontSize={'12px'} fontWeight={600} color={'brand.900'}>{item?.original?.measurements}</Text>
                    </Flex>
                    <Flex justifyContent={'center'}>
                        <Text color="secondaryGray.500" fontSize={'12px'} mr={'2px'}>T: </Text>
                        <Text fontSize={'12px'} fontWeight={600} color={'brand.900'}>{item?.original?.table}%</Text>
                    </Flex>
                    <Flex justifyContent={'center'}>
                        <Text color="secondaryGray.500" fontSize={'12px'} mr={'2px'}>D: </Text>
                        <Text fontSize={'12px'} fontWeight={600} color={'brand.900'}>{item?.original?.depth}%</Text>
                    </Flex>
                </Flex>
                <Flex className="diamond-detail-grid-row" justifyContent={'space-between'} alignItems={'center'}>
                    <Flex justifyContent={'center'}>
                        <Text color="secondaryGray.500" fontSize={'12px'} mr={1}>{t("LOCATION")}: </Text>
                        <Text fontSize={'12px'} fontWeight={600} color={'brand.900'} textAlign={'left'}>{_DIAMOND_LOCATION_OPTIONS.find(l => l.id == item?.original?.locationId)?.label}</Text>
                    </Flex>
                </Flex>
                <Flex className="diamond-detail-grid-row" justifyContent={'space-between'} alignItems={'center'}>
                    {item?.cells.filter(i => i.column.id == 'DETAIL')[0]?.render('Cell')}
                </Flex>
            </Flex>
        </Card>
    )
});

export default DiamondDetailGrid;