import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    Image,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    AbsoluteCenter,
    Stack,
    useDisclosure
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, isAdminPortal } from "../common/util";
import { showCustomDialog } from "../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineVideocam } from "react-icons/md";
import ApiEngine from "../common/api-engine";
import { WebUrl } from "../routes";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";

/// <summary>
/// Author: CK
/// </summary>
const DiamondMedia = React.forwardRef((props, ref) => {
    const {
        item,
        medias = [],
        showThumbnail = true,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const _MEDIAS = medias.length > 0 ? medias.sort((a, b) => a.mediaType.localeCompare(b.mediaType))
        :
        _DIAMOND_SHAPE_OPTIONS.filter(i => i.id == item?.shapeId).map(i => {
            return {
                mediaType: "Image",
                mediaUrl: i.defaultImg
            }
        });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleImageClick = (item) => {
        setSelectedItem(item);
        onOpen();
    };

    return (
        <Box className="diamond-media">
            <Flex>
                {
                    showThumbnail &&
                    <Flex flexDir={'column'} className="diamond-media-thumbnail-container" mr="5px">
                        {
                            _MEDIAS.map(i => {
                                return <Box className="diamond-media-thumbnail" mb="5px">
                                    {
                                        i.mediaType == "Image" ?
                                            <Image onClick={() => { handleImageClick(i); }} src={i.mediaUrl} />
                                            :
                                            <Button onClick={() => { handleImageClick(i); }}><MdOutlineVideocam size="24px" color="black" /></Button>
                                    }
                                </Box>
                            })
                        }
                    </Flex>
                }
                <Flex className="diamond-media-img-container">
                    <Image onClick={() => { handleImageClick(_MEDIAS.find(item => item.mediaType == 'Image')); }} className="diamond-media-img" src={_MEDIAS.find(item => item.mediaType == 'Image')?.mediaUrl} />
                </Flex>
            </Flex>
            {selectedItem && (
                <Modal isOpen={isOpen} onClose={onClose} size="full">
                    <ModalOverlay />
                    <ModalContent
                        containerProps={{
                            zIndex: '99999',
                        }}
                        bg="rgba(0,0,0,0.5)"
                        boxShadow="none"
                    >
                        <ModalCloseButton bg={'white'} boxShadow={'inherit'} color="black" />
                        <ModalBody display="flex" justifyContent="center" alignItems="center" p={0}>
                            {selectedItem.mediaType == 'Image' ? (
                                <Image src={selectedItem.mediaUrl} alt="Full Screen" maxH="100vh" />
                            ) : (
                                <Box
                                    as="iframe"
                                    src={selectedItem.mediaUrl}
                                    title="Full Screen Iframe"
                                    width="100vw"
                                    height="100vh"
                                    border="none"
                                    bg="white"
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    )
});

export default DiamondMedia;