import React from "react";
import { Role, DiamondColorType, DiamondType } from "./common/constant";
import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdWarning,
  MdUpload,
  MdOutlineDiamond,
  MdSearch,
  MdPersonAdd,
  MdGroup,
  MdOutlinePendingActions,
  MdOutlinePriceChange
} from "react-icons/md";
import { RiDraftFill, RiFileUserFill, RiUserFollowFill, RiFileShieldFill, RiShieldUserLine } from "react-icons/ri";
import { BsSave } from "react-icons/bs";
import { GiCutDiamond, GiEmerald, GiRingBox } from "react-icons/gi";
import { FaHandsHoldingCircle, FaFileInvoiceDollar, FaRegFile } from "react-icons/fa6";
import { TbDiamondFilled } from "react-icons/tb";
import MainDashboard from "./views/admin/default";
import Home from "./views/non-auth/home";
import SignIn from "./views/non-auth/signIn";
import Register from "./views/non-auth/register";
import ForgotPassword from "./views/non-auth/forgot-password";
import ChangePassword from "./views/non-auth/change-password";
import ProfileSettings from "./views/admin/jeweller/profile";
import SamplePage from "views/admin/sample-page";
import SearchDiamonds from "./views/admin/inventory/search-diamonds";
import Diamonds from "./views/admin/inventory/diamonds";
import HoldDiamondsList from "./views/admin/purchasement/hold-diamonds-list";
import ConfirmDiamondsList from "./views/admin/purchasement/confirm-diamonds-list";
import InvoiceList from "./views/admin/purchasement/invoice-list";
import ReadyForInvoiceList from "./views/admin/purchasement/ready-for-invoice-list";

/// <summary>
/// Author: Saitama
/// </summary>
export class WebUrl {
  static _DEFAULT = '/';
  static _SIGN_IN = '/sign-in';
  static _REGISTER = '/register';
  static _FORGOT_PASSWORD = '/forgot-password';
  static _CHANGE_PASSWORD = '/change-password';
  static _JEWELLER_PROFILE_SETTINGS = '/jeweller/profile-settings';
  static _SAMPLE = '/sample-page';
  static _NATURAL_DIAMOND_SEARCH = '/natural-diamond/search';
  static _NATURAL_FANCY_DIAMOND_SEARCH = '/natural-fancy-diamond/search';
  static _LAB_GROWN_DIAMOND_SEARCH = '/lab-grown-diamond/search';
  static _LAB_GROWN_FANCY_DIAMOND_SEARCH = '/lab-grown-fancy-diamond/search';
  static _INVENTORY_DIAMONDS = '/diamond/list';
  static _PURCHASEMENT_HOLD_DIAMOND_LIST = '/purchasement/hold-diamonds';
  static _PURCHASEMENT_CONFIRM_DIAMOND_LIST = '/purchasement/confirm-diamonds';
  static _PURCHASEMENT_INVOICE_LIST = '/purchasement/invoice';
  static _PURCHASEMENT_READY_FOR_INVOICE_LIST = '/purchasement/ready-for-invoice';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _NON_AUTH_ROUTES = [
  {
    name: "JW Diamonds",
    path: WebUrl._DEFAULT,
    component: <Home />,
  },
  {
    name: "SIGN_IN",
    path: WebUrl._SIGN_IN,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <SignIn />,
  },
  {
    name: "REGISTER",
    path: WebUrl._REGISTER,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <Register />,
  },
  {
    name: "FORGOT_PASSWORD",
    path: WebUrl._FORGOT_PASSWORD,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <ForgotPassword />,
  },
  {
    name: "CHANGE_PASSWORD",
    path: WebUrl._CHANGE_PASSWORD,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <ChangePassword />,
  },
]

/// <summary>
/// Author: Saitama
/// Remark: Not declaring role key mean open for all roles | empty array mean hide from all roles
/// </summary>
export const _AUTH_ROUTES = [
  {
    name: "MAIN_DASHBOARD",
    path: WebUrl._DEFAULT,
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: <MainDashboard />,
  },
  {
    name: "JEWELLER_SETTING",
    path: WebUrl._JEWELLER_PROFILE_SETTINGS,
    icon: <></>,
    component: <ProfileSettings />,
    isHidden: true
  },
  {
    name: "DIAMONDS",
    path: WebUrl._INVENTORY_DIAMONDS,
    icon: <></>,
    component: <Diamonds />,
    isHidden: true
  },
  {
    name: "NATURAL_DIAMONDS",
    category: 'NATURAL_DIAMONDS',
    items: [
      {
        name: "NATURAL_DIAMOND",
        path: WebUrl._NATURAL_DIAMOND_SEARCH,
        icon: <Icon as={GiCutDiamond} width='20px' height='20px' color='inherit' />,
        component: <SearchDiamonds diamondType={DiamondType._NATURAL} diamondColorType={DiamondColorType._WHITE} />
      },
      {
        name: "NATURAL_FANCY_DIAMOND",
        path: WebUrl._NATURAL_FANCY_DIAMOND_SEARCH,
        icon: <Icon as={GiEmerald} width='20px' height='20px' color='inherit' />,
        component: <SearchDiamonds diamondType={DiamondType._NATURAL} diamondColorType={DiamondColorType._FANCY_COLOR} />
      },
    ]
  },
  {
    name: "LAB_GROWN_DIAMONDS",
    category: 'LAB_GROWN_DIAMONDS',
    items: [
      {
        name: "LAB_GROWN_DIAMOND",
        path: WebUrl._LAB_GROWN_DIAMOND_SEARCH,
        icon: <Icon as={GiCutDiamond} width='20px' height='20px' color='inherit' />,
        component: <SearchDiamonds diamondType={DiamondType._LAB_GROWN} diamondColorType={DiamondColorType._WHITE} />
      },
      {
        name: "LAB_GROWN_FANCY_DIAMOND",
        path: WebUrl._LAB_GROWN_FANCY_DIAMOND_SEARCH,
        icon: <Icon as={GiEmerald} width='20px' height='20px' color='inherit' />,
        component: <SearchDiamonds diamondType={DiamondType._LAB_GROWN} diamondColorType={DiamondColorType._FANCY_COLOR} />
      },
    ]
  },
  {
    name: "PURCHASEMENT",
    category: 'PURCHASEMENT',
    items: [
      {
        name: "HOLD_DIAMONDS",
        path: WebUrl._PURCHASEMENT_HOLD_DIAMOND_LIST,
        icon: <Icon as={FaHandsHoldingCircle} width='20px' height='20px' color='inherit' />,
        component: <HoldDiamondsList />,
      },
      {
        name: "CONFIRM_GOODS",
        path: WebUrl._PURCHASEMENT_CONFIRM_DIAMOND_LIST,
        icon: <Icon as={GiRingBox} width='20px' height='20px' color='inherit' />,
        component: <ConfirmDiamondsList />,
      },
      {
        name: "READY_FOR_INVOICE",
        path: WebUrl._PURCHASEMENT_READY_FOR_INVOICE_LIST,
        icon: <Icon as={FaRegFile} width='20px' height='20px' color='inherit' />,
        component: <ReadyForInvoiceList />,
      },
      {
        name: "INVOICES",
        path: WebUrl._PURCHASEMENT_INVOICE_LIST,
        icon: <Icon as={FaFileInvoiceDollar} width='20px' height='20px' color='inherit' />,
        component: <InvoiceList />,
      },
    ]
  }
];
