import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
	Box,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	Flex,
	Text,
	Link
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import { Role, InvoiceStatus } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl, stringIsNullOrEmpty, renderInvoiceStatus } from "../../../common/util";
import moment from "moment";
import InvoiceDiamondDetail from "../../../components/invoice-diamond-detail";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
	var _dispatch = useDispatch();
	var _router = useRouter();
	const { t } = useTranslation();
	const _USER_DATA = useSelector((state) => state.authState.userData);
	const [apiUrl, setApiUrl] = useState(`${Routes._INVENTORY_INVOICE}`);

	const _COLUMNS = [
		{
			Header: "INVOICE_NO",
			accessor: "invoiceNo",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'} fontWeight={'bold'} fontSize={'14px'}>{row.original.invoiceNo}</Text>
					{
						row.original.status == InvoiceStatus._PENDING ?
							<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.createdAt).format('YYYY-MM-DD h:mm:ss A')}</Text> :
							<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
					}
				</Flex>
			}
		},
		{
			Header: "TOTAL_PIECE",
			accessor: "quantity",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'}>{row.original.quantity}</Text>
				</Flex>
			}
		},
		{
			Header: "TOTAL_CARAT",
			accessor: "totalCarat",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir={"column"}>
					<Text textAlign={'center'}>{row.original.totalCarat}</Text>
				</Flex>
			}
		},
		{
			Header: "TOTAL_PRICE",
			accessor: "totalPrice",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					<Text textAlign={'center'} fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalPrice)}</Text>
					<Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(row.original.totalPricePerCarat))}</Text>
				</Flex>
			}
		},
		{
			Header: "STATUS",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return <Flex flexDir="column" alignItems={'center'}>
					{renderInvoiceStatus(row.original.status)}
				</Flex>
			}
		},
		{
			Header: "COURIER",
			headerClassName: "col-align-center",
			Cell: ({ row }) => {
				return stringIsNullOrEmpty(row.original.logisticName) ?
					<Text textAlign={'center'}>{"-"}</Text> :
					<Link href={row.original.logisticTrackUrl} isExternal>
						{row.original.logisticName} <ExternalLinkIcon mx='2px' />
					</Link>
			}
		},
	];

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			<Card
				justifyContent='center'
				align='center'
				direction='column'
				w='100%'
				mb='0px'>
				<CustomTable
					columnsData={_COLUMNS}
					apiUrl={apiUrl}
					RowAccordionContent={InvoiceDiamondDetail}
				/>
			</Card>
		</Box>
	)
}