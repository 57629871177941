import React from "react";
import { useTranslation } from 'react-i18next';
import { FormLabel, Switch, Stack } from '@chakra-ui/react';

/// <summary>
/// Author: Saitama
/// </summary>
const CustomSwitch = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        label,
        style,
        size = 'lg',
        defaultChecked = false,
        isDisabled = false,
        ...restProps
    } = props;
    const { t } = useTranslation();

    return (
        <Stack mb={'20px'} {...style}>
            <FormLabel
                display={"flex"}
                ms={"4px"}
                fontSize={"md"}
                fontWeight={"500"}
                mb={"3px"}
            >
                {t(label)}
            </FormLabel>
            <Switch
                id={id}
                onChange={(e) => onChange(e.target.checked)}
                isDisabled={isDisabled}
                defaultChecked={defaultChecked}
                isChecked={value}
                colorScheme={'brand'}
                size={size}
                textAlign={'left'}
                {...restProps} />
        </Stack>
    )
});

export default CustomSwitch;