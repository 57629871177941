import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    Badge,
    Stack,
    Image
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    InvoiceStatus
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl } from "../common/util";
import { FaFileInvoiceDollar, FaCircleInfo } from "react-icons/fa6";
import { showCustomDialog } from "../application/action/app_action";
import ApiEngine from "../common/api-engine";
import { WebUrl } from "../routes";
import Routes from "../common/api_routes";
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author: CK
/// </summary>
const InvoiceDiamondDetail = React.forwardRef((props, ref) => {
    const {
        item,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);

    const renderStatusBadge = (inv) => {
        let colorScheme = inv.status == InvoiceStatus._SHIPPED ? "green" :
            inv.status == InvoiceStatus._PENDING ? "orange" : "blue";
        let text = inv.status == InvoiceStatus._SHIPPED ? "SHIPPED" :
            inv.status == InvoiceStatus._PENDING ? "PENDING_PAYMENT" : "PROCESSING";

        return <Flex flexDir="column" alignItems={'center'}>
            <Badge variant='subtle' colorScheme={colorScheme}>{t(text)}</Badge>
        </Flex>
    }

    return (
        <Box className="diamond-detail-accordion" bgColor={'gray.100'} p={'15px'}>
            <Flex flexDir={'column'}>
                <Flex flexDir={'row'} justifyContent={'space-between'} mb={"10px"}>
                    {renderStatusBadge(item)}
                    <Flex gap={2}>
                        <Button
                            colorScheme="teal"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaCircleInfo />}
                            onClick={() => { }}
                        >
                            {t('VIEW_INFO')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaFileInvoiceDollar />}
                            onClick={() => {
                                fetch(item.invoiceUrl, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/octet-stream',
                                    },
                                })
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                        const link = document.createElement('a');
                                        link.href = window.URL.createObjectURL(blob);
                                        link.download = "INV_" + item.invoiceNo
                                        document.body.appendChild(link);
                                        link.click();
                                        link.remove();
                                    })
                                    .catch((error) => console.error('Download error:', error));
                            }}
                        >
                            {t('VIEW_INVOICE')}
                        </Button>
                    </Flex>
                </Flex>
                <TableContainer width={'100%'}>
                    <Table variant='simple' className="hold-diamond-table">
                        <Thead backgroundColor={'gray.300'}>
                            <Tr>
                                <Th>{t("ORDER_NO")}</Th>
                                <Th>{t("DIAMOND_DETAILS")}</Th>
                                <Th>{t("LOCATION")}</Th>
                                <Th textAlign={'right'}>{t("TOTAL_PRICE")}<br />$/CT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                item.orders.map(order => {
                                    let diamondDetail = order.diamond != undefined ? order.diamond : order;
                                    let diamondImg = diamondDetail.diamondMedias.find(item => item.mediaType === 'Image');

                                    return (
                                        <Tr>
                                            <Td>
                                                <Text fontSize="15px">
                                                    {order.orderNo}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDirection={'row'} alignItems={'center'}>
                                                    {
                                                        diamondImg ?
                                                            <Image src={diamondImg.mediaUrl} />
                                                            : <Image src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == diamondDetail.shapeId)?.defaultImg} />
                                                    }
                                                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                                                        <Flex alignItems={'center'}>
                                                            <Flex alignItems={'center'} mr='5px'>
                                                                {renderDiamondTypeBadge(diamondDetail.typeId)}
                                                                <Text
                                                                    ml='3px'
                                                                    fontSize={'13px'}
                                                                >
                                                                    {diamondDetail.stockNo}
                                                                </Text>
                                                            </Flex>
                                                            <Link
                                                                fontSize={'13px'}
                                                                color='gold.600'
                                                                href={getLabCertificateTrackUrl(diamondDetail.labId, diamondDetail.certificateId)} isExternal>
                                                                {_DIAMOND_LAB_OPTIONS.find(i => i.id == diamondDetail.labId)?.label}-{diamondDetail.certificateId}
                                                            </Link>
                                                        </Flex>
                                                        <Text fontWeight={'bold'} fontSize={'16px'}>{diamondDetail.label}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Text fontSize="16px">
                                                    {_DIAMOND_LOCATION_OPTIONS.find(l => l.id == diamondDetail.locationId)?.label}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDir="column" alignItems={'flex-end'}>
                                                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(order.totalPrice)}</Text>
                                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(order.markupPricePerCarat ?? order.pricePerCarat))}</Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Box>
    )
});

export default InvoiceDiamondDetail;