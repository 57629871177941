import {
    ApiKey,
    _WEB_VERSION,
    SessionKey
} from '../../common/constant';
import Routes from '../../common/api_routes';
import { showCustomDialog } from './app_action';
import i18n from '../../localization/i18n';
import { ConfigEnum } from '../../common/config';
import {
    createFormBody,
    isObjectEmpty,
    stringIsNullOrEmpty
} from '../../common/util';
import ApiEngine from '../../common/api-engine';
import { AuthActionTypes } from '../reducer/auth_reducer';

/// <summary>
/// Author : Saitama
/// </summary>
export function performLogin(
    username,
    password
) {
    return async (dispatch) => {
        try {
            let params = {
                username: username,
                password: password,
                isAdminPortal: true
            };

            var responseJson = await ApiEngine.post(Routes._ACCOUNT_LOGIN, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            await localStorage.setItem(ConfigEnum._USER_DATA, JSON.stringify(responseJson[ApiKey._API_DATA_KEY]));
            await localStorage.setItem(ConfigEnum._BEARER_TOKEN, responseJson[ApiKey._API_DATA_KEY]['token']);

            dispatch({
                type: AuthActionTypes._LOGIN_SUCCESS,
                userData: responseJson[ApiKey._API_DATA_KEY]
            });
        }
        catch (err) {
            dispatch({
                type: AuthActionTypes._LOGIN_FAILED,
                message: i18n.t(err)
            });

            dispatch(
                showCustomDialog({
                    success: false,
                    content: i18n.t(err)
                })
            );
        }
    };
}

/// <summary>
/// Author : Saitama
/// </summary>
export const performLogout = () => {
    return async (dispatch, getState) => {
        let userData = getState().authState.userData;

        if (!isObjectEmpty(userData)) {
            try {
                await ApiEngine.post(Routes._ACCOUNT_LOGOUT);
            }
            catch (err) {

            }
        }

        localStorage.removeItem(ConfigEnum._BEARER_TOKEN);
        localStorage.removeItem(ConfigEnum._USER_DATA);

        await dispatch({
            type: AuthActionTypes._LOGOUT
        });
    };
};

/// <summary>
/// Author : Saitama
/// </summary>
export const checkIsLoggedIn = (skipLoading = true) => {
    return async (dispatch, getState) => {
        let userData = getState().authState.userData;

        if (!isObjectEmpty(userData)) {
            try {
                var responseJson = await ApiEngine.get(Routes._ACCOUNT_IS_LOGGED_IN, {headers: {"skipLoading": skipLoading}});

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                dispatch({
                    type: AuthActionTypes._UPDATE_USER,
                    user: responseJson[ApiKey._API_DATA_KEY]
                });
            }
            catch (err) {
                dispatch(
                    showCustomDialog({
                        success: false,
                        content: i18n.t(err),
                        onConfirm: () => performLogout()
                    })
                );
            }
        }


        // await dispatch({
        //     type: AuthActionTypes._LOGOUT
        // });
    };
};