export const AuthActionTypes = {
    _LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    _LOGIN_FAILED: 'LOGIN_FAILED',
    _LOGOUT: 'LOGOUT',
    _UPDATE_USER: 'UPDATE_USER',
}

const _INITIAL_STATE = {
    userData: {},
    isLoggedIn: false,
    loginMessage: "",
    loginStateInitialized: false
};

/// <summary>
/// Author : Saitama
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActionTypes._LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                isLoggedIn: action.userData?.isLoggedIn !== undefined ? action.userData?.isLoggedIn : true,
                loginMessage: "",
                loginStateInitialized: true,
            }
        case AuthActionTypes._LOGIN_FAILED:
            return {
                ...state,
                loginMessage: action.message,
                isLoggedIn: false,
                userData: {},
                loginStateInitialized: true,
            }
        case AuthActionTypes._LOGOUT:
            return {
                ...state,
                userData: {},
                isLoggedIn: false,
                loginMessage: "",
                loginStateInitialized: true,
            };
        case AuthActionTypes._UPDATE_USER:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...action.user
                }
            }
        default:
            return state;
    }
};