import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button, Icon, useColorMode, Flex, Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup
} from "@chakra-ui/react";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { LuLanguages } from "react-icons/lu";
import { _LANGUAGE_OPTION } from "../../common/constant";
import { ConfigEnum } from "../../common/config";

/// <summary>
/// Author: Saitama
/// </summary>
export default function FixedPlugin(props) {
  const { t, i18n } = useTranslation();
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  /// <summary>
  /// Author: Saitama
  /// </summary>
  function onChangeLanguage(language) {
    i18n.changeLanguage(language);
    localStorage.setItem(ConfigEnum._LANGUAGE, language);
  }

  return (
    <Flex
      position={'fixed'}
      zIndex={'99'}
      bottom={'30px'}
      right={"35px"}
      gap={'5'}>
      <Menu>
        <MenuButton
          {...rest}
          h='60px'
          w='60px'
          bg={bgButton}
          variant='no-effects'
          border='1px solid'
          borderColor='#6A53FF'
          borderRadius='50px'
          onClick={() => { }}
          display='flex'
          p='0px'
          align='center'
          justify='center'>
          <Icon
            h='24px'
            w='24px'
            color='white'
            as={LuLanguages}
          />
        </MenuButton>
        <MenuList>
          <MenuOptionGroup defaultValue={localStorage.getItem(ConfigEnum._LANGUAGE)}>
            {
              _LANGUAGE_OPTION.map((item, index) => {
                return (
                  <MenuItemOption
                    key={index}
                    type='radio'
                    value={item.value}
                    onClick={() => onChangeLanguage(item.value)}>
                    {item.label}
                  </MenuItemOption>
                )
              })
            }
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      <Button
        {...rest}
        h='60px'
        w='60px'
        bg={bgButton}
        variant='no-effects'
        border='1px solid'
        borderColor='#6A53FF'
        borderRadius='50px'
        onClick={toggleColorMode}
        display='flex'
        p='0px'
        align='center'
        justify='center'>
        <Icon
          h='24px'
          w='24px'
          color='white'
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>
    </Flex>
  );
}
